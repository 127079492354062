import { render, staticRenderFns } from "./companyBrief.vue?vue&type=template&id=af7a0ac0&scoped=true"
import script from "./companyBrief.vue?vue&type=script&lang=js"
export * from "./companyBrief.vue?vue&type=script&lang=js"
import style0 from "./companyBrief.vue?vue&type=style&index=0&id=af7a0ac0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af7a0ac0",
  null
  
)

export default component.exports