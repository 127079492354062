import { render, staticRenderFns } from "./companyDetail.vue?vue&type=template&id=36cc1096&scoped=true"
import script from "./companyDetail.vue?vue&type=script&lang=js"
export * from "./companyDetail.vue?vue&type=script&lang=js"
import style0 from "./companyDetail.vue?vue&type=style&index=0&id=36cc1096&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36cc1096",
  null
  
)

export default component.exports